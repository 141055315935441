import coin from "src/assets/images/logo.png";
import "./JfinCoin.css";

const JfinCoin = () => {
  return (
    <div className="jfin-coin-container">
      <img src={coin} alt="jfin coin" />
    </div>
  );
};

export default JfinCoin;
