import React from "react";

const Conditions = () => {
  return (
    <div className="condition-content" style={{ maxHeight: "80vh" }}>
      <article>
        <h3 className="jfin-cond">ข้อตกลง JFIN Staking</h3>
        <p>
          ขอให้ผู้ที่สนใจการ Staking โทเคนดิจิทัล JFIN Token
          (ซึ่งต่อไปนี้จะเรียกว่า &quot;Staker&quot;)
          อ่านข้อความดังต่อไปนี้ให้ครบถ้วนก่อนทำการ Stake บนระบบบล็อกเชน JFIN
          Chain ซึ่งให้บริการโดย บริษัท เจดีเอ็น จำกัด (&quot;JDN&quot;) และ
          บริษัท เจ เวนเจอร์ส จำกัด (&quot;JVC&quot;){" "}
        </p>
        <p>
          Staker ตกลงและยอมรับรายละเอียดของโครงการ JFIN Token
          รวมถึงข้อกำหนดและเงื่อนไขของโครงการทั้งหมดตามที่ได้ระบุไว้ในเอกสาร
          JFIN Token - Native Token for JFIN Chain Whitepaper version 3.0
          ฉบับมีผลบังคับใช้วันที่ 17 กรกฎาคม 2565 เป็นต้นไป
          (สามารถเข้าถึงได้ที่:
          <a href="https://www.jfincoin.io/">https://www.jfincoin.io/</a>)
          รวมถึงฉบับที่จะได้มีการแก้ไขเปลี่ยนแปลงของเอกสารฉบับดังกล่าวด้วย และ
          Staker ยินยอมปฏิบัติตามข้อกำหนดและเงื่อนไขดังกล่าว
          รวมถึงกฎระเบียบและวิธีการในการ Staking ตามที่ JDN และ/หรือ JVC
          จะกำหนดและแจ้งให้ทราบเป็นครั้งคราวทุกประการ ในกรณีที่ Staker
          ไม่ยอมรับข้อกำหนดหรือเงื่อนไขใด ๆ Staker
          จะไม่เข้าทำธุรกรรมหรือใช้บริการใด ๆ ที่เกี่ยวข้องกับโครงการ JFIN Token
          หรือในกรณีที่ Staker ได้เข้าทำธุรกรรมหรือใช้บริการใด ๆ
          ที่เกี่ยวข้องกับโครงการ JFIN Token อยู่แล้ว Staker
          จะหยุดการทำธุรกรรมหรือใช้บริการทันที
        </p>
        <p>
          2. Staker รับทราบและยอมรับว่า ในกรณีที่ Validator Node ที่ Staker
          ได้ทำการ Stake JFIN Token
          ด้วยถูกตัดสิทธิในการเข้าร่วมตรวจสอบยืนยันการทำธุรกรรม (Validation)
          ตามข้อกำหนดและเงื่อนไขของโครงการ JFIN Token นั้น Staker
          อาจไม่ได้รับค่าตอบแทน block reward ในช่วงระยะเวลาที่ Validator Node
          ถูกตัดสิทธิดังกล่าว และหากเกิดความเสียหายใด ๆ แก่ Staker JDN และ/หรือ
          JVC จะไม่ต้องรับผิดในความเสียหายดังกล่าวนั้น
        </p>
        <p>
          3. Staker รับทราบและยอมรับว่า ค่าตอบแทน block reward ที่ Staker
          ได้รับจาก JVC สำหรับการที่ Staker นำ JFIN Token มาทำการ Stake
          บนระบบบล็อกเชน JFIN Chain ของ JDN นั้น
        </p>
        <p>
          3.1)อาจอยู่ในบังคับต้องหักภาษี ณ ที่จ่ายตามกฎหมาย ในการนี้ Staker
          ตกลงยินยอมให้ JVC ผู้จ่ายเงินได้หักภาษี ณ
          ที่จ่ายไว้ตามอัตราที่กฎหมายกำหนดเพื่อนำส่งให้แก่กรมสรรพากร
        </p>
        <p>
          3.2)อาจถือเป็นกรณีที่ JVC ให้บริการทางอิเล็กทรอนิกส์แก่ Staker
          อันอยู่ในบังคับต้องเสียภาษีมูลค่าเพิ่ม ในการนี้ JVC และ Staker
          ตกลงให้ค่าตอบแทน block reward
          ดังกล่าวเป็นมูลค่าที่รวมภาษีมูลค่าเพิ่มตามอัตราที่กฎหมายกำหนดแล้ว และ
          Staker ตกลงยินยอมให้ JVC หักค่าตอบแทน block reward
          ไว้เป็นค่าภาษีมูลค่าเพิ่มที่ Staker จะต้องชำระให้แก่ JVC
        </p>
        <p>
          Staker รับทราบว่า ในกรณีที่ Staker ไม่ได้ให้ข้อมูลแก่ JVC
          อย่างครบถ้วนเพียงพอ JVC จะไม่สามารถออกหนังสือรับรองการหักภาษี ณ
          ที่จ่าย และใบกำกับภาษีแบบเต็มรูปให้แก่ Staker รายดังกล่าวได้ นอกจากนี้
          Staker ตกลงและรับทราบว่า ค่าตอบแทน block reward (ในรูปของ JFIN Token)
          ที่ JVC หักไว้เป็นค่าภาษีหัก ณ ที่จ่าย และภาษีมูลค่าเพิ่มดังกล่าว JVC
          มีสิทธินำ JFIN Token
          ดังกล่าวไปจำหน่ายจ่ายโอนหรือแลกเปลี่ยนเป็นเงินบาทเพื่อนำเงินบาทไปนำส่งค่าภาษีหัก
          ณ ที่จ่าย และนำไปชำระค่าภาษีมูลค่าเพิ่มต่อกรมสรรพากร ทั้งนี้
          ภาระภาษีอากรที่เกี่ยวข้องกับการจำหน่ายจ่ายโอนหรือแลกเปลี่ยนดังกล่าว
          Staker ตกลงเป็นผู้รับผิดชอบทั้งสิ้นแต่ฝ่ายเดียว (ถ้ามี)
        </p>
        <p>
          4. Staker รับทราบและยอมรับว่า ในการใช้สิทธิรับค่าตอบแทน block reward
          สำหรับการที่ Staker นำ JFIN Token มาทำการ Stake มีค่าธรรมเนียม (gas
          fee) ในการทำธุรกรรมเพื่อใช้สิทธิรับค่าตอบแทน block reward แต่ละครั้ง
        </p>
      </article>
    </div>
  );
};
export default Conditions;
